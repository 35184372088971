<template>
  <v-sheet
    class="gradientBackground"
    :class="$vuetify.breakpoint.xsOnly ? 'pt-4' : 'py-8'"
  >
    <v-container>
      <v-row align="center">
        <v-col cols="12" md="6">
          <v-card dark class="transparent" elevation="0">
            <p
              class="font-weight-bold mb-2"
              :class="$vuetify.breakpoint.xsOnly ? 'text-h4' : 'text-h2'"
            >
              A new mattress without the cost of one
            </p>
            <p :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h4'">
              Starting from RM329
            </p>
            <p
              class="discountReveal"
              :class="
                $vuetify.breakpoint.xsOnly ? 'text-subtitle-1' : 'ttext-h6'
              "
              style="opacity: 0.9"
            ></p>
            <v-btn x-large outlined to="padding-details">
              <v-icon color="white" class="mr-2">mdi-shopping</v-icon>
              Buy
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-0">
          <v-img
            class="introImg"
            src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/mattress-service-changeIT.jpg?v=1661149805"
            transition="scale-transition"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Sine, TextPlugin } from "gsap/all";
export default {
  name: "SDServiceIntro",
  mounted() {
    this.$gsap.registerPlugin(ScrollTrigger);
    this.$gsap.registerPlugin(TextPlugin);

    this.$gsap.to(".discountReveal", {
      text: "*50% off for first-time customers",
      ease: "power1",
      duration: 5,
    });

    this.$gsap.to(".introImg", {
      scrollTrigger: { trigger: ".introImg", start: "top top", scrub: 1 },
      opacity: 0.2,
      ease: Sine.easeInOut,
    });

    let b1 = "linear-gradient(to top right, #3ab0ff, #ffb562)";
    let b2 = "linear-gradient(to bottom left, #F94892, #21E1E1)";

    this.$gsap.fromTo(
      ".gradientBackground",
      { background: b1 },
      {
        ease: Sine.easeInOut,
        background: b2,
        duration: 5,
        repeat: -1,
        yoyo: true,
      }
    );
  },
};
</script>
