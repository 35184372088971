<template>
  <v-footer padless>
    <padding-intro-divider></padding-intro-divider>
    <v-card color="primary" class="pt-5" width="100%" tile>
      <v-container fluid style="min-height: 250px">
        <v-row :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
          <v-col cols="12" md="3" class="pa-5">
            <v-card flat color="primary">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      :ripple="false"
                      class="transparent"
                      width="180"
                      height="70"
                      to="/"
                      elevation="0"
                    >
                      <v-img
                        contain
                        width="180"
                        height="70"
                        src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/sd-services-logo.png?v=1660839014"
                        transition="scale-transition"
                      ></v-img>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>

              <v-btn
                v-for="(icon, i) in icons"
                :key="i"
                class="mr-5 mb-4"
                color="secondary"
                icon
                @click="openLink(icon.path)"
              >
                <v-icon size="24px">
                  {{ icon.name }}
                </v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="py-5">
            <p class="font-weight-bold secondary--text">ABOUT</p>
            <v-divider
              class="mb-1 secondary"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-6' : 'mr-6'"
            ></v-divider>
            <v-list nav dense class="transparent">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in about"
                  :key="i"
                  :to="item.path"
                  active-class="white--text secondary"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-medium"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="3" class="py-5">
            <p class="font-weight-bold secondary--text">SUPPORT</p>
            <v-divider
              class="mb-1 secondary"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-6' : 'mr-6'"
            ></v-divider>
            <v-list nav dense class="transparent">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in support"
                  :key="i"
                  :to="item.path"
                  active-class="white--text secondary"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-medium"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="3" class="py-5">
            <p class="font-weight-bold secondary--text">SLEEP PRODUCTS</p>
            <v-divider
              class="mb-1 secondary"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-6' : 'mr-6'"
            ></v-divider>
            <v-list nav dense class="transparent">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in products"
                  :key="i"
                  :to="item.path"
                  active-class="white--text secondary"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-medium"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mb-5">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.xsOnly" sm="3"></v-col>
          <v-col v-for="(payOption, n) in paymentOpts" :key="n" cols="2" sm="1">
            <v-img contain :src="payOption" style="border-radius: 4px"> </v-img>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <p class="text-center my-8">
        <b>
          SWEETDREAM LIFESTYLE SDN BHD
          <span style="font-size: 10px">(195175-P)</span>
        </b>
        <br />
        (FKA FIBRECO MANUFACTURER (M) Sdn. Bhd.)
      </p>
      <p class="text-center py-5">
        Take a rest<v-icon class="mx-5">mdi-copyright</v-icon>Copyright
        {{ new Date().getFullYear() }}
      </p>
    </v-card>
  </v-footer>
</template>

<script>
import PaddingIntroDivider from "../Dividers/PaddingIntroDivider.vue";
const paymentOptionSrc = [
  "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/visa-logo.png?v=1636368924",
  "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/paypal-logo.png?v=1636368924",
  "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/mastercard-logo.png?v=1636368924",
  "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/ipay88-logo.png?v=1636368924",
  "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/shop-back-paymentOption.png?v=1658809947",
  "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/grabPay-logo.png?v=1636368924",
];
export default {
  components: { PaddingIntroDivider },
  data() {
    return {
      paymentOpts: paymentOptionSrc,
      selectedItem: 1,
      about: [{ text: "Contact Us", path: "/contact-us" }],
      support: [{ text: "FAQs", path: "/faqs" }],
      products: [{ text: "Padding", path: "/padding-details" }],
      icons: [
        { name: "mdi-facebook", path: "https://facebook.com/sweetdreammsia" },
        {
          name: "mdi-linkedin",
          path: "https://my.linkedin.com/company/sweetdream-lifestyle-sdn-bhd",
        },
        { name: "mdi-instagram", path: "https://instagram.com/sweetdreammsia" },
        {
          name: "mdi-youtube",
          path: "https://www.youtube.com/channel/UCzJVPu_Tx4rIVj7yh5jS3kg",
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
