<template>
  <v-bottom-sheet v-model="showMenu">
    <v-sheet class="pb-16" style="background: rgba(0, 0, 0, 0.7)">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-img
              transition="scale-transition"
              v-if="$vuetify.breakpoint.xsOnly"
              alt="SweetDream Services Logo"
              contain
              src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/sd-services-whiteLogo.png?v=1662196726"
            />
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="6" align="center">
            <v-card>
              <v-list rounded dense>
                <v-subheader>Services</v-subheader>
                <v-list-item-group v-model="selectedItem" color="secondary">
                  <v-list-item
                    v-for="(item, i) in productButtonLinks"
                    :key="i"
                    :to="item.path"
                    @click="dismissMenu"
                    active-class="secondary white--text"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-left">
                        <v-icon
                          v-text="item.icon"
                          class="mr-2"
                          active-class="white"
                        ></v-icon>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <v-btn
              class="my-5 py-5"
              color="secondary darken-1"
              width="100%"
              to="/"
              @click="dismissMenu"
              >Home</v-btn
            >
          </v-col>
          <v-col cols="6">
            <v-row justify="center" align="center">
              <v-card class="my-5" width="90%">
                <v-list rounded dense>
                  <v-subheader>Support</v-subheader>
                  <v-list-item-group v-model="selectedItem" color="secondary">
                    <v-list-item
                      v-for="(item, i) in moreInfoButtonLinks"
                      :key="i"
                      :to="item.path"
                      @click="dismissMenu"
                      active-class="secondary white--text"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon
                            class="mr-3"
                            v-text="item.icon"
                            active-class="white"
                          ></v-icon>
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  data: () => ({
    showMenu: false,
    selectedItem: 0,

    productButtonLinks: [
      {
        text: "Padding",
        icon: "mdi-bed-king",
        imageIcon: false,
        image: "",
        path: "/padding-details",
      },
    ],
    moreInfoButtonLinks: [
      {
        text: "Contact Us",
        icon: "mdi-phone",
        imageIcon: false,
        image: "",
        path: "/contact-us",
      },
      {
        text: "FAQs",
        icon: "mdi-forum",
        imageIcon: false,
        image: "",
        path: "/faqs",
      },
    ],
    supportButtonLinks: [
      {
        text: "Warranty",
        icon: "mdi-weather-cloudy",
        imageIcon: false,
        image: "",
        path: "/warranty",
      },
      {
        text: "Stickers",
        icon: "mdi-weather-cloudy",
        imageIcon: true,
        image:
          "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/tiny-bed-stickers-06.png?v=1651648131",
        path: "/tiny-bed-stickers",
      },
    ],
  }),
  props: {
    mobileMenu: Boolean,
  },
  watch: {
    mobileMenu(val) {
      this.showMenu = val;
    },
    showMenu(val) {
      if (val == false) {
        this.$emit("doneWithMenu");
      }
    },
  },
  created() {},
  destroyed() {},
  methods: {
    dismissMenu() {
      this.$emit("doneWithMenu");
    },
  },
  mounted() {},
};
</script>
