<template>
  <v-app>
    <v-app-bar
      v-if="$vuetify.breakpoint.mdAndUp"
      height="100px"
      hide-on-scroll
      color="primary"
      elevation="10"
      app
      dark
      style="z-index: 55"
    >
      <v-spacer></v-spacer>
      <v-btn
        outlined
        light
        class="transparent underlineButton"
        to="/padding-details"
        >Buy</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn outlined to="/contact-us" light class="transparent underlineButton"
        >Contact Us</v-btn
      >

      <v-spacer></v-spacer>
      <v-icon large light>mdi-fleur-de-lis</v-icon>
      <v-spacer></v-spacer>
      <v-btn to="/" class="transparent" elevation="0">
        <v-img
          alt="SweetDream Services Logo"
          contain
          src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/sd-services-logo.png?v=1660839014"
          transition="scale-transition"
          width="200px"
          height="200px"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <v-icon large light>mdi-fleur-de-lis</v-icon>
      <v-spacer></v-spacer>
      <v-btn outlined light class="transparent underlineButton" to="/faqs"
        >FAQs</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        @click="openLink('https://sweetdream.com.my')"
        outlined
        light
        class="transparent underlineButton"
        >Beds & Pillows</v-btn
      >

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-app-bar v-else color="primary" hide-on-scroll app>
      <v-btn icon @click="mobileMenu = !mobileMenu" color="secondary">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn to="/" class="transparent" elevation="0">
        <v-img
          alt="SweetDream Services Logo"
          contain
          src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/sd-services-logo.png?v=1660839014"
          transition="scale-transition"
          width="100px"
          height="100px"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon color="secondary" to="padding-details">
        <v-icon>mdi-shopping</v-icon>
      </v-btn>
    </v-app-bar>

    <mobile-menu
      :mobileMenu="this.mobileMenu"
      v-on:doneWithMenu="dismissMenu"
    ></mobile-menu>

    <v-main>
      <router-view></router-view>
      <site-footer></site-footer>
    </v-main>
  </v-app>
</template>

<script>
import SiteFooter from "./components/KeyAreas/SiteFooter.vue";
import MobileMenu from "./components/KeyAreas/MobileMenu.vue";

export default {
  name: "App",
  components: {
    SiteFooter,
    MobileMenu,
  },
  data() {
    return {
      mobileMenu: false,
      leftAppBarLinks: [
        { title: "Packages", link: "/packages" },
        { title: "FAQs", link: "/faqs" },
      ],
      rightAppBarLinks: [
        { title: "Contact Us", link: "/contact-us" },
        { title: "Buy", link: "/buy" },
      ],
    };
  },
  methods: {
    dismissMenu() {
      this.mobileMenu = false;
    },
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.underlineButton {
  border-bottom: 1px solid black !important;
  padding-bottom: 1.5px;
  border-radius: 0px;
}
</style>
