import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "@/views/Home.vue";
import FAQs from "@/views/CustomerInfo/FAQs.vue";
import ContactUs from "@/views/CompanyInfo/ContactUs.vue";
import PaddingDetails from "@/views/ShoppingViews/PaddingDetails.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "SweetDream Services",
      metaTags: [
        {
          name: "description",
          content:
            "Welcome to the official SweetDream Services website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
        {
          property: "og:description",
          content:
            "Welcome to the official SweetDream Services website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
      ],
    },
  },
  {
    path: "/faqs",
    name: "faqs",
    component: FAQs,
    meta: {
      title: "SweetDream Services FAQs",
      metaTags: [
        {
          name: "description",
          content:
            "Welcome to the official SweetDream website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
        {
          property: "og:description",
          content:
            "Welcome to the official SweetDream website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
      ],
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
    meta: {
      title: "SweetDream Services Contact Us",
      metaTags: [
        {
          name: "description",
          content:
            "Welcome to the official SweetDream website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
        {
          property: "og:description",
          content:
            "Welcome to the official SweetDream website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
      ],
    },
  },
  {
    path: "/padding-details",
    name: "padding-details",
    component: PaddingDetails,
    meta: {
      title: "SweetDream Services Padding",
      metaTags: [
        {
          name: "description",
          content:
            "Welcome to the official SweetDream website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
        {
          property: "og:description",
          content:
            "Welcome to the official SweetDream website and online store. Get exclusive prices for mattress, and pillows!  Find out more about what makes us one of the leading local mattress brands in Malaysia. Partner with us for exciting campaigns. Take A Rest.",
        },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
