<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="white--text text-h6 font-weight-bold mb-1">
          How does SweetDream Services work?
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pa-2"
        :cols="i == 2 ? 12 : 6"
        md="4"
        v-for="(step, i) in steps"
        :key="i"
      >
        <v-card class="primary pa-4">
          <p
            class="font-weight-bold blue--text"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h4'"
          >
            {{ step.title }}
            <v-icon large :class="`finishedStepTick-${i}`" color="green"
              >mdi-check</v-icon
            >
          </p>
          <p
            class="mb-1"
            :class="$vuetify.breakpoint.xsOnly ? 'text-body-2' : 'text-h6'"
          >
            {{ step.info }}
          </p>
          <p
            class="grey--text"
            :class="$vuetify.breakpoint.xsOnly ? 'text-body-2' : 'text-h6'"
          >
            + {{ step.subInfo }}
          </p>
          <v-chip outlined class="mb-0">Est. time: ~{{ step.time }}</v-chip>
        </v-card>
      </v-col>
      <v-col cols="12" align="center">
        <v-btn x-large color="white" outlined to="padding-details">
          <v-icon color="white" class="mr-2">mdi-shopping</v-icon>
          Buy
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Sine } from "gsap";

export default {
  data() {
    return {
      steps: [
        {
          title: "Step 1",
          info: "Choose your preferred padding",
          subInfo: "schedule a pick-up/delivery date.",
          time: "20 mins",
        },
        {
          title: "Step 2",
          info: "Sleep on a free SweetDream mattress",
          subInfo: "while we install your new padding.",
          time: "1 week",
        },
        {
          title: "Step 3",
          info: "Enjoy your new mattress feel",
          subInfo: "with a 1-year guarantee",
          time: "1 year",
        },
      ],
    };
  },
  mounted() {
    this.$gsap.registerPlugin(ScrollTrigger);

    const timeline = this.$gsap.timeline();
    const stepTicks = [
      ".finishedStepTick-0",
      ".finishedStepTick-1",
      ".finishedStepTick-2",
    ];

    timeline.set(stepTicks, {
      opacity: 0,
    });
    timeline
      .to(".finishedStepTick-0", {
        opacity: 1,
        duration: 1,
        ease: Sine.easeIn,
      })
      .to(".finishedStepTick-1", {
        opacity: 1,
        duration: 1,
        ease: Sine.easeIn,
      })
      .to(".finishedStepTick-2", {
        opacity: 1,
        duration: 1,
        ease: Sine.easeIn,
      });

    ScrollTrigger.create({
      animation: timeline,
      start: this.$vuetify.breakpoint.xsOnly ? "top center" : "top bottom",
      trigger: ".finishedStepTick-0",
      toggleActions: "play pause resume restart",
    });
  },
};
</script>
