<!-- eslint-disable -->
<template>
  <v-container fluid class="pa-0" style="overflow: hidden">
    <v-container :class="$vuetify.breakpoint.xsOnly ? 'py-5' : 'pt-10 pb-5'">
      <v-row align="center">
        <v-col cols="12" md="7">
          <p
            class="font-weight-medium mb-2"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h4'"
            style="color: grey"
          >
            Get your sleep questions answered
          </p>
          <p
            class="font-weight-bold mb-0 pb-0"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h3'"
          >
            Wake up refreshed
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet
          class="pb-8 gradientBackground"
          style="
            background-image: linear-gradient(to bottom left, #3ab0ff, #ffb562);
            position: relative;
          "
        >
          <WaveDivider></WaveDivider>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-sheet
                  style="position: relative"
                  class="d-flex justify-center"
                >
                  <v-img
                    contain
                    src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/tinyBed-morning.png?v=1651648131"
                    width="50%"
                    class="tinyBedOne"
                    style="position: absolute"
                  ></v-img>
                  <v-img
                    contain
                    src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/tiny-bed-question.png?v=1653544300"
                    width="50%"
                    class="tinyBedTwo"
                    style="position: absolute"
                  ></v-img>
                  <v-img
                    contain
                    src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/tiny-bed-stickers-05.png?v=1651648131"
                    width="50%"
                    class="tinyBedThree"
                    style="position: absolute"
                  ></v-img>
                  <v-img
                    contain
                    src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/tinyBed-yes.png?v=1651648131"
                    width="50%"
                    class="tinyBedFour"
                    style="position: absolute"
                  ></v-img>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6">
                <v-container>
                  <v-row>
                    <v-col cols="12" v-for="(card, n) in cards" :key="n">
                      <v-card outlined class="pa-5 rounded-xl">
                        <v-icon class="mb-5" color="secondary">
                          {{ card.icon }}</v-icon
                        >
                        <p
                          class="mb-1"
                          :class="
                            $vuetify.breakpoint.smAndDown ? 'text-body-2' : ''
                          "
                        >
                          {{ card.subtitleOne }}
                        </p>
                        <p
                          style="word-break: keep-all"
                          class="font-weight-bold"
                          :class="
                            $vuetify.breakpoint.smAndDown
                              ? 'text-h6'
                              : 'text-h5'
                          "
                        >
                          {{ card.title }}
                        </p>
                        <p
                          class="mb-0"
                          :class="
                            $vuetify.breakpoint.smAndDown
                              ? 'text-subtitle-2'
                              : ''
                          "
                        >
                          {{ card.subtitleTwo }}
                        </p>
                        <v-btn
                          color="secondary"
                          class="ma-3"
                          outlined
                          v-if="card.button"
                          :to="card.path"
                        >
                          {{ card.buttonText }}
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- Intro part -->
  </v-container>
</template>

<script>
import WaveDivider from "../../components/Dividers/WaveDivider.vue";
import { Sine } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

var contactCards = [
  {
    icon: "mdi-phone",
    subtitleOne: "MOBILE CONTACT",
    title: "012 268 6992",
    subtitleTwo: "You may also contact 012 719 7992 for project inquiries",
    button: false,
    buttonText: "",
    path: "",
  },
  {
    icon: "mdi-mail",
    subtitleOne: "COMPANY NAME",
    title: "SweetDream Lifestyle Sdn Bhd (195175-P)",
    subtitleTwo: "Formerly known as Fibreco Manufacturer Sdn Bhd",
    button: false,
    buttonText: "",
    path: "",
  },
  {
    icon: "mdi-email",
    subtitleOne: "EMAIL ADDRESS",
    title: "hello@sweetdream.com.my",
    subtitleTwo:
      "For urgent enquiries you may also e-mail to custcare@sweetdream.com.my",
    button: false,
    buttonText: "",
    path: "",
  },
  {
    icon: "mdi-home",
    subtitleOne: "COMPANY ADDRESS",
    title:
      "No. 21 Kepayang Sentral, Jalan KS 3, Taman Bukit Kepayang, 70200 Seremban, Negeri Sembilan",
    subtitleTwo: "Check out more SweetDream locations",
    button: false,
    buttonText: "",
    path: "",
  },
];

export default {
  data() {
    return {
      cards: contactCards,
    };
  },
  components: { WaveDivider },
  mounted() {
    this.$gsap.registerPlugin(ScrollTrigger);

    this.$gsap.set(".tinyBedOne", {
      yPercent: 0,
      xPercent: -50,
      rotate: -20,
    });
    this.$gsap.set(".tinyBedTwo", {
      yPercent: 60,
      xPercent: 50,
      rotate: 20,
    });
    this.$gsap.set(".tinyBedThree", {
      yPercent: 120,
      xPercent: -50,
      rotate: 30,
    });
    this.$gsap.set(".tinyBedFour", {
      yPercent: 180,
      xPercent: 50,
      rotate: -30,
    });

    const tinyBeds = [
      ".tinyBedOne",
      ".tinyBedTwo",
      ".tinyBedThree",
      ".tinyBedFour",
    ];

    this.$gsap.to(tinyBeds, {
      scrollTrigger: {
        scrub: 1,
      },
      rotate: 0,
    });

    this.$gsap.to(".contactUsImg", {
      scrollTrigger: {
        trigger: ".contactUsImg",
        start: "top bottom",
        scrub: 1,
      },
      yPercent: 0,
    });
    let b1 = "linear-gradient(to top right, #3ab0ff, #ffb562)";
    let b2 = "linear-gradient(to bottom left, #F94892, #21E1E1)";

    this.$gsap.fromTo(
      ".gradientBackground",
      { background: b1 },
      {
        ease: Sine.easeInOut,
        background: b2,
        duration: 5,
        repeat: -1,
        yoyo: true,
      }
    );
  },
};
</script>
