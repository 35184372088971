<template>
  <v-container class="pt-10">
    <v-row align="center">
      <v-col cols="12" md="6" align="center">
        <v-img
          width="50%"
          class="planetImg"
          src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/earth-vectorImg.png?v=1661751818"
        ></v-img>
      </v-col>
      <v-col cols="12" md="6">
        <v-card elevation="0" class="transparent">
          <p
            class="font-weight-bold"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h3'"
          >
            Sleep for the
            <span class="green white--text">&nbsp;planet&nbsp;</span>
          </p>
          <p :class="$vuetify.breakpoint.xsOnly ? '' : 'text-h6'">
            Service your mattress to prolong its lifespan and keep it away from
            being disposed to landfills
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  mounted() {
    this.$gsap.registerPlugin(ScrollTrigger);

    const timeline = this.$gsap.timeline();

    timeline.to(".planetImg", {
      scrollTrigger: {
        trigger: ".planetImg",
        start: "bottom bottom",
        scrub: 1,
      },
      scale: 1.5,
    });
  },
};
</script>
