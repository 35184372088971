<template>
  <v-container class="pb-0">
    <v-row align="center">
      <v-col cols="12" md="6">
        <v-card elevation="0">
          <p
            class="font-weight-bold"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h3'"
          >
            Sleep with
            <span class="secondary white--text">&nbsp;ease&nbsp;</span>
          </p>
          <p :class="$vuetify.breakpoint.xsOnly ? '' : 'text-h6'">
            Our amazing servicemen each have at least
            <span class="font-weight-bold">5 years</span> professional
            experience in mattress-handling, cleaning, and installation.
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-img
          transition="scale-transition"
          src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Cleaning_Team-01_2.png?v=1661924520"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
