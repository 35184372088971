import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#F9F2ED",
        secondary: "#3AB0FF",
        accent: "#FFB562",
      },
      dark: {
        primary: "#4A4B4D",
        secondary: "#D79922",
        accent: "#064663",
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
});
