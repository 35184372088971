<template>
  <v-container fluid class="pa-0" style="overflow: hidden">
    <v-sheet class="pa-4" style="border-bottom: 1px solid #aaaaaa">
      <p class="mb-0 font-weight-bold text-h6">Frequently Asked Questions</p>
    </v-sheet>
    <v-container :class="$vuetify.breakpoint.xsOnly ? 'py-5' : 'pt-10 pb-5'">
      <v-row align="center">
        <v-col cols="12" md="7">
          <p
            class="font-weight-bold"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h3'"
          >
            What's on your mind?
          </p>
          <p>
            We know you might have a few questions about how our service works &
            operate. It's exciting to find out more and you're in the right
            place to do so! Enjoy a more simple & informative buying experience
            with our FAQs.
          </p>
        </v-col>
        <v-col cols="12" md="5">
          <p
            class="font-weight-bold"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5'"
          >
            Have a custom question?
          </p>
          <p>
            Message us on live chat or WhatsApp us directly to get your
            questions answered.
          </p>
          <iframe
            width="80%"
            height="50"
            style="border: 0"
            src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=standard&radius=4px&label=Message us on WhatsApp&number=60122686992"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet
          class="pb-8 gradientBackground"
          style="
            background-image: linear-gradient(to bottom left, #3ab0ff, #ffb562);
            position: relative;
          "
        >
          <WaveDivider></WaveDivider>
          <v-container>
            <v-row>
              <v-col cols="12" md="8" class="pt-0">
                <!-- General FAQs -->
                <p
                  class="font-weight-bold white--text"
                  :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h4'"
                >
                  General
                  <v-icon color="white" class="ml-2">
                    mdi-chevron-down-circle
                  </v-icon>
                </p>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(faq, i) in generalFaqs" :key="i">
                    <v-expansion-panel-header
                      color="primary"
                      class="font-weight-medium"
                    >
                      <p class="mb-0">
                        <v-icon class="mr-2" color="black"
                          >mdi-fleur-de-lis</v-icon
                        >
                        {{ faq.question }}
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-3">
                      <p class="mb-0">
                        {{ faq.answer }}
                      </p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- Delivery FAQs -->
                <p
                  class="font-weight-bold white--text mt-6"
                  :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h4'"
                >
                  Delivery
                  <v-icon color="white" class="ml-2">
                    mdi-chevron-down-circle
                  </v-icon>
                </p>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(faq, i) in deliveryFaqs" :key="i">
                    <v-expansion-panel-header
                      color="primary"
                      class="font-weight-medium"
                    >
                      <p class="mb-0">
                        <v-icon class="mr-2" color="black"
                          >mdi-fleur-de-lis</v-icon
                        >
                        {{ faq.question }}
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-3">
                      <p class="mb-0">
                        {{ faq.answer }}
                      </p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- Return FAQs -->
                <p
                  class="font-weight-bold white--text mt-6"
                  :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h4'"
                >
                  Returns & Exchanges
                  <v-icon color="white" class="ml-2">
                    mdi-chevron-down-circle
                  </v-icon>
                </p>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(faq, i) in returnFaqs" :key="i">
                    <v-expansion-panel-header
                      color="primary"
                      class="font-weight-medium"
                    >
                      <p class="mb-0">
                        <v-icon class="mr-2" color="black"
                          >mdi-fleur-de-lis</v-icon
                        >
                        {{ faq.question }}
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-3">
                      <p class="mb-0">
                        {{ faq.answer }}
                      </p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-img
                  class="faqPerson rounded-lg elevation-2"
                  src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/lady-making-bed.jpg?v=1662086083"
                ></v-img>
                <v-img
                  class="faqPersonTwo rounded-lg elevation-4"
                  src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/polyester-pillow-people.jpg?v=1638175430"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WaveDivider from "../../components/Dividers/WaveDivider.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Sine } from "gsap";

export default {
  components: { WaveDivider },
  data() {
    return {
      generalFaqs: [
        {
          question: "Does the service void my warranty?",
          answer:
            "It entirely depends on your mattress provider. However, in most cases the warranty will be void so it is advisable to service your mattress with us after your warranty is expired (or nearing its end).",
        },
        {
          question: "Can I send a mattress from any brand to service?",
          answer:
            "Yes BUT due to how some mattress are built, we are unable to service unconventional ones (full foam, coconut fibre). In general, we are able to service any mattress with a spring system unit.",
        },
        {
          question: "How long will the service take?",
          answer:
            "From start to finish, around 8 working days. We have to carefully install your chosen padding and perform a complimentary cleaning/packing service.",
        },
        {
          question:
            "Will my mattress feel different from original after a service?",
          answer:
            "Yes, our foam is formulated to SweetDream™ certified standards (which are really high) so depending on your mattress brand - the overall feel will definitely be different.",
        },
        {
          question: "Are the foams you use brand new?",
          answer:
            "Yes. We only install new foams that are made fresh & stored properly.",
        },
        {
          question: "Where is your service center located?",
          answer: "Our service centre is located in Seremban, Negeri Sembilan!",
        },
        {
          question: "What is the location(s) coverage?",
          answer:
            "Only Klang Valley for the time being. We are currently unable to serve customers in East Malaysia or overseas",
        },
      ],
      deliveryFaqs: [
        {
          question: "How will delivery be scheduled?",
          answer:
            "Once your order has been placed - we will reach out to you via WhatsApp to schedule the earliest delivery slot!",
        },
        {
          question: "How can I check my order status?",
          answer:
            "You may drop us a live chat message on the bottom right or contact us through the WhatsApp number. Please provide your order number.",
        },
        {
          question: "Can I specify weekend deliveries?",
          answer:
            "Of course you can. You may also specify a certain time with us too (morning deliveries as early as 7AM!)",
        },
        {
          question: "What is included in the delivery?",
          answer:
            "Our deliveries are all-inclusive. We pick up & deliver your mattress from the bedroom with professional manpower, disposal, and wrapping services. Free-of-charge.",
        },
      ],
      returnFaqs: [
        {
          question:
            "Can I get a refund if I'm not satisfied with my serviced mattress?",
          answer:
            "No. We do not provide refunds or exchanges due to the nature of the service.",
        },
        {
          question: "I would like to purchase the rental mattress. How?",
          answer:
            "You may most certainly do so! Get in touch with us to secure your purchase.",
        },
        {
          question: "My mattress broke apart after one month. What can I do?",
          answer:
            "You may schedule a delivery with us to replace the foam free-of-charge. We provide a 8-month guarantee on our installed foams and check the overall condition before installation so this unfortunate scenario is very rare. ",
        },
      ],
    };
  },
  mounted() {
    this.$gsap.registerPlugin(ScrollTrigger);

    this.$gsap.set(".faqPerson", {
      y: -100,
    });
    this.$gsap.to(".faqPerson", {
      scrollTrigger: { trigger: ".faqPerson", scrub: 1 },
      y: 0,
    });
    this.$gsap.set(".faqPersonTwo", {
      x: 50,
      yPercent: 10,
    });
    this.$gsap.to(".faqPersonTwo", {
      scrollTrigger: { trigger: ".faqPersonTwo", scrub: 1 },
      yPercent: -10,
    });

    let b1 = "linear-gradient(to top right, #3ab0ff, #ffb562)";
    let b2 = "linear-gradient(to bottom left, #F94892, #21E1E1)";

    this.$gsap.fromTo(
      ".gradientBackground",
      { background: b1 },
      {
        ease: Sine.easeInOut,
        background: b2,
        duration: 5,
        repeat: -1,
        yoyo: true,
      }
    );
  },
};
</script>
