<template>
  <v-sheet color="#F2D6C1" width="100%"> </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      paddingImgSrc: [
        "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Latex_Foam.png?v=1661420128",
        "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Memory_Foam.png?v=1661420127",
        "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_SweetDream_Foam.png?v=1661420127",
      ],
    };
  },
};
</script>
