<template>
  <v-container class="pt-5 pa-0" style="overflow: hidden" fluid>
    <v-container>
      <v-row>
        <v-col cols="12">
          <p class="secondary--text font-weight-medium mb-1">New in 2022</p>
          <p
            class="font-weight-bold"
            :class="$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h3'"
          >
            Buy Padding Change
          </p>
        </v-col>
      </v-row>
      <v-row>
        <!-- Padding Image -->
        <v-col cols="12" md="6">
          <v-card
            :height="$vuetify.breakpoint.xsOnly ? '300px' : '500px'"
            elevation="0"
            outlined
            style="border-width: 2px"
            class="d-flex align-center justify-center rounded-xl productImg"
          >
            <v-img
              src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Latex_Foam.png?v=1661420128"
              class="latexFoam"
              width="90%"
              contain
              style="position: absolute; z-index: 51"
            ></v-img>
            <v-img
              src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Memory_Foam.png?v=1661420127"
              class="memoryFoam"
              width="90%"
              contain
              style="position: absolute; z-index: 51"
            ></v-img>
            <v-img
              src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_SweetDream_Foam.png?v=1661420127"
              class="sdFoam"
              width="90%"
              contain
              style="position: absolute; z-index: 51"
            ></v-img>
            <v-img
              src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_01Base.png?v=1661419497"
              class="bottomHalf"
              width="90%"
              contain
              style="position: absolute; z-index: 50"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Foam/Padding Selection -->
          <p class="font-weight-bold text-h6" id="choosePadding">
            <v-icon class="mr-2" color="secondary">
              mdi-numeric-1-circle-outline
            </v-icon>
            Select a padding.
            <span class="grey--text font-weight-medium"
              >3 exciting choices.</span
            >
          </p>
          <v-card
            elevation="0"
            class="pa-5 mb-3 rounded-xl"
            v-for="(padding, i) in paddingChoices"
            :key="`paddingChoice-${i}`"
            @click="
              goToSection('#chooseSizeSection', 1800, -50);
              currentPadding = i;
              changePadding(i);
              updatePricing();
            "
            :dark="i == currentPadding"
            :color="i == currentPadding ? 'secondary' : 'grey lighten-3'"
          >
            <v-container class="pa-2">
              <v-row align="center">
                <v-col cols="8">
                  <p class="text-h6 mb-1 font-weight-medium">
                    {{ padding.title }}
                  </p>
                  <p class="mb-0 text-body-2">{{ padding.content }}</p>
                </v-col>
                <v-col cols="4">
                  <p class="mb-0 text-end font-weight-medium">
                    {{ padding.price }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <!-- Size Selection -->
          <p class="font-weight-bold text-h6 mt-8" id="chooseSizeSection">
            <v-icon class="mr-2" color="secondary">
              mdi-numeric-2-circle-outline
            </v-icon>
            Select a size.
            <span class="grey--text font-weight-medium"
              >Which suits your mattress.</span
            >
          </p>
          <v-chip-group
            mandatory
            center-active
            column
            active-class="success--text success"
          >
            <v-chip
              v-for="(variant, i) in sizeChoices"
              :key="`sizeChoice-${i}`"
              class="ma-2"
              color="secondary"
              label
              @click="
                currentSize = i;
                updatePricing();
                goToSection('#chooseRentalMattress', 1500, -50);
              "
              :input-value="i == currentSize"
              filter
              :x-large="$vuetify.breakpoint.mdAndUp"
              :large="$vuetify.breakpoint.smAndDown"
              outlined
              :style="$vuetify.breakpoint.smOnly ? 'width:100%' : ''"
            >
              {{ variant.title }}
              <span class="font-weight-bold ml-1 text-subtitle-2"
                >+ RM{{ variant.price }}</span
              >
            </v-chip>
          </v-chip-group>

          <!-- Rental Mattress Selection -->
          <p class="font-weight-bold text-h6 mt-8" id="chooseRentalMattress">
            <v-icon class="mr-2" color="secondary">
              mdi-numeric-3-circle-outline
            </v-icon>
            Select a rental mattress.
            <span class="grey--text font-weight-medium"
              >SweetDream quality.</span
            >
          </p>
          <v-card
            elevation="0"
            class="pa-3 mb-3 rounded-xl"
            :dark="i == currentRentalMattress"
            :color="i == currentRentalMattress ? 'secondary' : 'grey lighten-3'"
            v-for="(choice, i) in mattressChoices"
            :key="`mattressChoice-${i}`"
            @click="
              goToSection('#completionInfo', 1500, -50);
              currentRentalMattress = i;
            "
          >
            <v-container class="pa-2">
              <v-row align="center">
                <v-col cols="4">
                  <v-img :src="choice.imgSrc"></v-img>
                </v-col>
                <v-col cols="8">
                  <p
                    class="text-end font-weight-bold mb-0"
                    :class="i == currentRentalMattress ? '' : 'blue--text'"
                  >
                    FREE
                  </p>
                  <p class="text-h6 mb-1 font-weight-bold">
                    {{ choice.title }}
                  </p>
                  <p class="mb-0">{{ choice.content }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <!-- Completion Info Area -->
      <v-row>
        <v-col cols="12">
          <v-card
            class="grey lighten-3 rounded-xl my-5"
            elevation="0"
            id="completionInfo"
          >
            <v-row align="center">
              <v-col cols="12" md="5" class="pa-0">
                <v-img
                  class="completeSelectionImg"
                  :class="
                    $vuetify.breakpoint.smAndDown
                      ? ''
                      : 'rounded-lg elevation-6'
                  "
                  src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/girl-no-neckPain.jpg?v=1662014374"
                ></v-img>
              </v-col>
              <v-col cols="12" md="7" class="pa-5">
                <v-container>
                  <v-row align="center">
                    <v-col cols="10" class="py-0">
                      <p class="grey--text mb-0">Review Selection</p></v-col
                    >
                    <v-col cols="2" class="py-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            large
                            icon
                            color="secondary"
                            @click="goToSection('#choosePadding', 1500, 50)"
                          >
                            <v-icon large
                              >mdi-arrow-up-thin-circle-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Move to top</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider class="mb-3"></v-divider>
                <v-sheet class="ml-2 transparent">
                  <p class="text-h5 mb-1 font-weight-bold">
                    Your new mattress awaits.
                  </p>
                  <p class="text-h5 grey--text font-weight-medium">
                    Designed by you, completed by us
                  </p>
                  <v-card
                    outlined
                    class="pa-3 rounded-xl"
                    :class="$vuetify.breakpoint.smAndDown ? '' : 'mr-5'"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="5">
                          <p class="font-weight-bold">
                            Padding Choice
                            <v-icon color="success" class="ml-1"
                              >mdi-check</v-icon
                            >
                          </p>
                          <v-divider
                            style="border-width: 0.8px"
                            class="mb-2 secondary"
                          ></v-divider>
                          <v-img
                            height="100px"
                            contain
                            :src="paddingChoices[currentPadding].imgSrc"
                          ></v-img>
                          <p class="mb-1 font-weight-medium">
                            {{ paddingChoices[currentPadding].title }}
                          </p>
                          <p class="mb-0">
                            <v-icon color="secondary" size="18"
                              >mdi-ruler</v-icon
                            >
                            <span class="font-weight-medium">
                              {{ sizeChoices[currentSize].title }}
                            </span>
                            size
                          </p>
                        </v-col>
                        <v-col cols="12" md="5">
                          <p class="font-weight-bold">
                            Rental Mattress
                            <v-icon color="success" class="ml-1"
                              >mdi-check</v-icon
                            >
                          </p>
                          <v-divider
                            style="border-width: 0.8px"
                            class="mb-2 secondary"
                          ></v-divider>
                          <v-img
                            height="100px"
                            contain
                            :src="
                              mattressChoices[currentRentalMattress]
                                .sideImgSrc != ''
                                ? mattressChoices[currentRentalMattress]
                                    .sideImgSrc
                                : mattressChoices[currentRentalMattress].imgSrc
                            "
                          ></v-img>
                          <p class="font-weight-medium">
                            {{ mattressChoices[currentRentalMattress].title }}
                          </p>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                          <v-chip label outlined color="#1363DF">
                            <b>W</b>&nbsp;{{ sizeChoices[currentSize].width }} x
                            &nbsp; <b>L</b>&nbsp;{{
                              sizeChoices[currentSize].length
                            }}
                            x&nbsp;
                            <b>H</b>&nbsp;{{ sizeChoices[currentSize].height }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        :align="
                          $vuetify.breakpoint.smAndDown ? 'center' : 'start'
                        "
                      >
                        <p class="mt-2 mb-0 text-h5 font-weight-bold">
                          RM{{
                            (this.currentPrice * this.$currentDiscount).toFixed(
                              2
                            )
                          }}
                          &nbsp;
                          <span
                            v-if="$currentDiscount != 1"
                            class="text-decoration-line-through font-weight-regular grey--text"
                            >RM{{ this.currentPrice }}</span
                          >
                        </p>
                        <p class="grey--text mt-3">
                          Est. completion time: 8 working days
                        </p>
                        <v-btn x-large color="secondary" @click="buyNow()">
                          <v-icon color="white" class="mr-2"
                            >mdi-shopping</v-icon
                          >
                          Buy
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet
          class="pb-8 mt-8 gradientBackground"
          style="
            background-image: linear-gradient(to bottom left, #3ab0ff, #ffb562);
            position: relative;
          "
        >
          <WaveDivider></WaveDivider
        ></v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";
import WaveDivider from "../../components/Dividers/WaveDivider.vue";
export default {
  name: "padding-details",
  data() {
    return {
      currentPrice: 0,
      currentPadding: 0,
      currentRentalMattress: 0,
      currentSize: 0,
      paddingChoices: [
        {
          title: "SweetDream™ Foam",
          price: "From RM329",
          content: "Long-lasting support with high density.",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_SweetDream_Foam.png?v=1661420127",
        },
        {
          title: "Memory SD™ Foam",
          price: "From RM379",
          content: "Pressure and pain relief with consistent softness.",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Memory_Foam.png?v=1661420127",
        },
        {
          title: "Latex SD™ Foam",
          price: "From RM439",
          content: "Natural breathability with better temperature control.",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Latex_Foam.png?v=1661420128",
        },
      ],
      sizeChoices: [
        {
          title: "Single",
          width: "91cm",
          length: "190cm",
          height: "7cm",
          price: "",
        },
        {
          title: "Super Single",
          width: "106cm",
          length: "190cm",
          height: "7cm",
          price: "",
        },
        {
          title: "Queen",
          width: "152cm",
          length: "190cm",
          height: "7cm",
          price: "",
        },
        {
          title: "King",
          width: "182cm",
          length: "190cm",
          height: "7cm",
          price: "",
        },
      ],
      mattressChoices: [
        {
          title: "Tender Hypersoft",
          content: "All foam with specialised 3D technology",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Tender_Hypersoft-01.png?v=1661927670",
          sideImgSrc: "",
          price: "FREE",
        },
        {
          title: "Legend Bronze YY",
          content: "Bonnell spring system bestseller",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Legendary-YY_01-1.png?v=1661928934",
          sideImgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/YY_Side_Mattress_Full.png?v=1662084329",
          price: "FREE",
        },
      ],
      shopifyProductID: "",
      shopifyProducts: [],
    };
  },
  mounted() {
    this.$client.collection
      .fetchWithProducts("gid://shopify/Collection/289404551346")
      .then((collection) => {
        // Do something with the collection
        this.shopifyProducts = collection.products;
        this.shopifyProductID =
          collection.products[this.currentPadding].variants[
            this.currentSize
          ].id;
        this.updatePricing();
      });

    this.$gsap.registerPlugin(ScrollTrigger);
    this.$gsap.set(".completeSelectionImg", {
      y: 100,
    });
    this.$gsap.to(".completeSelectionImg", {
      scrollTrigger: { trigger: ".completeSelectionImg", scrub: 1 },
      y: 0,
    });
    // Latex is first upper half
    this.$gsap.set(".latexFoam", {
      xPercent: -100,
      opacity: 0,
    });
    // Memory Foam is third upper half
    this.$gsap.set(".memoryFoam", {
      xPercent: -100,
      opacity: 0,
    });
    this.$gsap.set(".bottomHalf", { yPercent: 30 });
    this.$gsap.to(".productImg", {
      scrollTrigger: {
        trigger: ".productImg",
        pin: true,
        start: "top top",
        end: this.$vuetify.breakpoint.xsOnly ? "+=10" : "+=550",
      },
    });
  },
  methods: {
    updatePricing() {
      this.currentPrice =
        this.shopifyProducts[this.currentPadding].variants[
          this.currentSize
        ].price;
      this.sizeChoices.map((size, i) => {
        size.price =
          this.shopifyProducts[this.currentPadding].variants[i].price;
      });
    },
    buyNow() {
      this.shopifyProductID =
        this.shopifyProducts[this.currentPadding].variants[this.currentSize].id;
      var checkoutId;
      const discountCode = this.$currentDiscountCode;

      this.$client.checkout.create().then((checkout) => {
        checkoutId = checkout["id"];
        const lineItemsToAdd = [
          {
            variantId: this.shopifyProductID,
            quantity: 1,
            customAttributes: [
              {
                key: "Rental Mattress",
                value: this.mattressChoices[this.currentRentalMattress].title,
              },
            ],
          },
        ];

        this.$client.checkout.addDiscount(checkoutId, discountCode);

        // Add an item to the checkout
        this.$client.checkout
          .addLineItems(checkoutId, lineItemsToAdd)
          .then((checkout) => {
            // Do something with the updated checkout
            window.open(checkout["webUrl"], "_self");
          });
      });
    },
    // Change Padding Animation
    changePadding(index) {
      if (index == 0) {
        // index 1 is SweetDream foam
        this.$gsap.to(".sdFoam", { xPercent: 0, opacity: 1 });
        this.$gsap.to(".latexFoam", { xPercent: -100, opacity: 0 });
        this.$gsap.to(".memoryFoam", { xPercent: -100, opacity: 0 });
      } else if (index == 1) {
        // index 1 is memory foam
        this.$gsap.to(".memoryFoam", { xPercent: 0, opacity: 1 });
        this.$gsap.to(".latexFoam", { xPercent: -100, opacity: 0 });
        this.$gsap.to(".sdFoam", { xPercent: -100, opacity: 0 });
      } else if (index == 2) {
        // index 2 is latex
        this.$gsap.to(".latexFoam", { xPercent: 0, opacity: 1 });
        this.$gsap.to(".memoryFoam", { xPercent: -100, opacity: 0 });
        this.$gsap.to(".sdFoam", { xPercent: -100, opacity: 0 });
      }
    },
    goToSection(section, duration, offset) {
      this.$vuetify.goTo(section, {
        duration: duration,
        offset: offset,
      });
    },
  },
  components: { WaveDivider },
};
</script>
