<template>
  <v-container fluid class="pa-0" style="overflow: hidden">
    <v-row>
      <v-col cols="12">
        <SDServiceIntro></SDServiceIntro>
      </v-col>
    </v-row>

    <padding-change></padding-change>
    <v-sheet style="position: relative" class="pb-8 gradientBackground">
      <WaveDivider></WaveDivider>
      <steps-explainer></steps-explainer>
    </v-sheet>
    <planet-explainer></planet-explainer>
    <who-cleans-explainer></who-cleans-explainer>
    <!-- <voucher-section></voucher-section>
    <padding-explainer></padding-explainer>
    <clean-explainer></clean-explainer> -->
  </v-container>
</template>

<script>
import SDServiceIntro from "@/components/Intros/SDServiceIntro.vue";
import WaveDivider from "../components/Dividers/WaveDivider.vue";
import PaddingChange from "../components/Animations/PaddingChange.vue";
import StepsExplainer from "../components/Explainers/StepsExplainer.vue";
import PlanetExplainer from "../components/Explainers/PlanetExplainer.vue";
// import CleanExplainer from "../components/CleanExplainer.vue";
// import PaddingExplainer from "../components/PaddingExplainer.vue";
// import VoucherSection from "../components/VoucherSection.vue";
import { Sine } from "gsap";
import WhoCleansExplainer from "../components/Explainers/WhoCleansExplainer.vue";

export default {
  components: {
    SDServiceIntro,
    WaveDivider,
    PaddingChange,
    PlanetExplainer,
    StepsExplainer,
    WhoCleansExplainer,
  },
  mounted() {
    let b1 = "linear-gradient(to top right, #3ab0ff, #ffb562)";
    let b2 = "linear-gradient(to bottom left, #F94892, #21E1E1)";

    this.$gsap.fromTo(
      ".gradientBackground",
      { background: b1 },
      {
        ease: Sine.easeInOut,
        background: b2,
        duration: 5,
        repeat: -1,
        yoyo: true,
      }
    );
  },
};
</script>
