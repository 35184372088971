<template>
  <v-container class="pb-8">
    <v-row>
      <v-col cols="12">
        <p
          class="pa-5 font-weight-bold text-center mb-0 secondary--text"
          :class="$vuetify.breakpoint.xsOnly ? 'text-h4 pb-2' : 'text-h2'"
          style="z-index: 53"
        >
          Transform your mattress
        </p>
        <p
          class="pb-8 px-8 text-center"
          :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h4'"
        >
          with 3 padding choices
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center">
        <v-card
          :height="$vuetify.breakpoint.xsOnly ? '180px' : '280px'"
          elevation="0"
          class="d-flex justify-center"
        >
          <v-img
            src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Latex_Foam.png?v=1661420128"
            class="firstUpperHalf"
            :width="$vuetify.breakpoint.xsOnly ? '70vw' : '40vw'"
            contain
            style="position: absolute; z-index: 51"
          ></v-img>
          <v-img
            src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_Memory_Foam.png?v=1661420127"
            class="secondUpperHalf"
            :width="$vuetify.breakpoint.xsOnly ? '70vw' : '40vw'"
            contain
            style="position: absolute; z-index: 51"
          ></v-img>
          <v-img
            src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_SweetDream_Foam.png?v=1661420127"
            class="thirdUpperHalf"
            :width="$vuetify.breakpoint.xsOnly ? '70vw' : '40vw'"
            contain
            style="position: absolute; z-index: 51"
          ></v-img>
          <v-img
            src="https://cdn.shopify.com/s/files/1/0518/4241/6818/files/Padding_01Base.png?v=1661419497"
            class="bottomHalf"
            :width="$vuetify.breakpoint.xsOnly ? '70vw' : '40vw'"
            contain
            style="position: absolute; z-index: 50"
          ></v-img>
        </v-card>
        <p class="font-weight-medium">
          Service available to almost every mattress brand
        </p>

        <v-avatar
          tile
          size="100"
          class="mr-6"
          v-for="(brand, i) in mattressBrandLogos"
          :key="i"
          style="cursor: pointer"
        >
          <v-img
            @click="openLink(brand.link)"
            transition="scale-transition"
            contain
            height="60px"
            :src="brand.imgSrc"
          ></v-img>
        </v-avatar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Power3 } from "gsap/all";

export default {
  data() {
    return {
      mattressBrandLogos: [
        {
          title: "Sealy",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/sealy-logo.png?v=1662183192",
          link: "https://sealy.com.my/",
        },
        {
          title: "SweetDream Malaysia",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/sd-logo-darkBackground.png?v=1662192134",
          link: "https://sweetdream.com.my/",
        },
        {
          title: "King Koil",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/King-Koil-logo.png?v=1662183191",
          link: "https://www.kingkoil.my/",
        },
        {
          title: "Getha",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/getha-md.jpg.sb-c3267388-1btdgQ?v=1662192554",
          link: "http://getha.com.my",
        },
        {
          title: "Slumberland",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/slumberland_logo.png?v=1662183190",
          link: "https://slumberland.com.my/",
        },
        {
          title: "Dunlopillo",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0518/4241/6818/files/dunlopillo_logo.png?v=1662183192",
          link: "http://dunlopilloworld.com/my",
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
  mounted() {
    const timeline = this.$gsap.timeline({ repeat: -1 });

    var firstPosition = 0;
    var secondPosition = 100;
    var thirdPosition = -100;
    timeline.set(".secondUpperHalf", {
      xPercent: secondPosition,
      rotation: -45,
      transformOrigin: "left 20%",
      opacity: 0.4,
    });
    timeline.set(".thirdUpperHalf", {
      xPercent: thirdPosition,
      rotation: 45,
      transformOrigin: "right 20%",
      opacity: 0.4,
    });
    timeline.set(".bottomHalf", { yPercent: 15 });

    function right() {
      return timeline
        .add("start")
        .to(
          ".firstUpperHalf",
          {
            xPercent: (firstPosition += 100),
            ease: Power3.easeInOut,
            duration: 3,
            opacity: firstPosition >= 100 ? 0.4 : 1,
          },
          "start"
        )
        .to(
          ".secondUpperHalf",
          {
            xPercent: (secondPosition += 100),
            rotation:
              secondPosition == 200 ? 0 : secondPosition == 100 ? -45 : 0,
            transformOrigin: "left 20%",
            ease: Power3.easeInOut,
            duration: 3,
            opacity: secondPosition >= 100 ? 0.4 : 1,
          },
          "start"
        )
        .to(
          ".thirdUpperHalf",
          {
            xPercent: (thirdPosition += 100),
            rotation: thirdPosition == 0 ? 0 : thirdPosition == -100 ? 45 : 0,
            transformOrigin: "right 20%",
            ease: Power3.easeInOut,
            duration: 3,
            opacity: thirdPosition <= -100 ? 0.4 : 1,
          },
          "start"
        );
    }

    function left() {
      return timeline
        .add("start")
        .to(
          ".firstUpperHalf",
          {
            xPercent: (firstPosition -= 100),
            ease: Power3.easeInOut,
            duration: 3,
            opacity: firstPosition <= -100 ? 0.4 : 1,
          },
          "start"
        )
        .to(
          ".secondUpperHalf",
          {
            xPercent: (secondPosition -= 100),
            rotation: secondPosition == 100 ? -45 : 0,
            transformOrigin: "left 20%",
            ease: Power3.easeInOut,
            duration: 3,
            opacity: secondPosition >= 100 ? 0.4 : 1,
          },
          "start"
        )
        .to(
          ".thirdUpperHalf",
          {
            xPercent: (thirdPosition -= 100),
            rotation: thirdPosition == -100 ? 45 : 0,
            transformOrigin: "right 20%",
            ease: Power3.easeInOut,
            duration: 3,
            opacity: thirdPosition <= -100 ? 0.4 : 1,
          },
          "start"
        );
    }

    right();
    left();
    left();
    right();
  },
};
</script>
