import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Client from "shopify-buy";

import Router from "../router/router.js";

import { gsap } from "gsap";

Vue.config.productionTip = false;

const client = Client.buildClient({
  domain: "sweetdreammsia.myshopify.com",
  storefrontAccessToken: "c6ee63ef2652b4d099352f81722c7a58",
});
Vue.prototype.$gsap = gsap;

Vue.prototype.$client = client;

// Global variables for automatic discounts
Vue.prototype.$currentDiscount = 0.5;
Vue.prototype.$currentDiscountCode = "LAUNCHPROMO50";

new Vue({
  router: Router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
